import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import Progress from '../util/Progress';
import * as Constants from '../../constants/global';
import VerificarEmail from './VerificarEmail';
import PoliticaModal from '../modal/PoliticaModal';
import Cobertura from './Cobertura';

import novisibility from '../../assets/icon/hidden.svg';
import visibility from '../../assets/icon/visibility.svg';

const DatosPersonales = (props) => {

    const [loader, setLoader] = useState(false);
    const [error, setError] = useState("");
    const [errorg, setErrorg] = useState("");

    const [politica, setPolitica] = useState(0);
    const [tipoDoc, setTipoDoc] = useState();
    const [documento, setDocumento] = useState();  
    const [celular, setCelular] = useState();  
    const [email, setEmail] = useState();  
    const [contrasena, setContrasena] = useState();
    const [nombre, setNombre] = useState();
    const [apellidos, setApellidos] = useState();
    const [terminos, setTerminos] = useState(); 
    const [habeas, setHabeas] = useState(); 
    const [registro, setRegistro] = useState(false);
    const [regresar, setRegresar] = useState(false);

    const [hidden, setHidden] = React.useState(false);

    const handleRegistro = () => {
        setErrorg("");
        setError("");
        setLoader(true);
        let url = Constants.URL_BASE + Constants.WSREGISTRO
        let data = {
            "nombres": nombre,
            "apellidos": apellidos,
            "doc": documento,
            "tipoDoc": tipoDoc,
            "telefono": celular,
            "email": email,
            "clave": contrasena,
            "tyC": Number(terminos) || 0,
            "habeasData": Number(habeas) || 0,
            "departamentoId": Number(props.cobertura.departamento ? props.cobertura.departamento : 0),
            "municipioId": Number(props.cobertura.municipio ? props.cobertura.municipio: 0),
            "barrioId": Number(props.cobertura.barrio ? props.cobertura.barrio : 0),
            "direccion": props.cobertura.direccion,
            "referencia": props.cobertura.referencia,
            "latitud": props.cobertura.latitud,
            "longitud": props.cobertura.longitud
        }

        axios.post(url, data).then((response)=>{
            setLoader(false);
            if(response.data.message){
                setRegistro(true)
            }
        }).catch((err)=>{
            setLoader(false);           
            if(err.response.data.errors){
                setError(err.response.data.errors);
            }

            if(err.response.data.error){
                setErrorg(err.response.data.error);
            }

            if(terminos){
              document.getElementById('terminos_condiciones').checked=true  
            }else{
                setTerminos();
                setHabeas();
            }
        });
    }

    const handlePolitica = item => {
        setPolitica(0);
    }

    const handleBack = () => {
        props.updateCobertura(true);
        //props.history.goBack(props)
    }

    const handleCheckbox = (check) =>{
        var checkbox = document.getElementById(check);
        checkbox.addEventListener("change", validaCheckbox, false);
        
        function validaCheckbox(){
          var checked = checkbox.checked;
          if(checked){
            if(check=="terminos_condiciones"){
                setTerminos(1);
                setHabeas(1);
            }
               
          }else{
            if(check=="terminos_condiciones"){
                setTerminos();
                setHabeas();
            }
                
          }
        }
    }

    const handleInput = (id_input) => {

        const input = document.getElementById(id_input);

        if(input){

            input.addEventListener('keydown', function(evento) {
                const elemento = evento.target;
                const teclaPresionada = evento.key;
                const teclaPresionadaEsUnNumero =
                Number.isInteger(parseInt(teclaPresionada));

                const sePresionoUnaTeclaNoAdmitida = 
                teclaPresionada != 'ArrowDown' &&
                teclaPresionada != 'ArrowUp' &&
                teclaPresionada != 'ArrowLeft' &&
                teclaPresionada != 'ArrowRight' &&
                teclaPresionada != 'Backspace' &&
                teclaPresionada != 'Delete' &&
                teclaPresionada != 'Enter' &&
                teclaPresionada != 'Tab' &&
                !teclaPresionadaEsUnNumero;
                const comienzaPorCero = 
                elemento.value.length === 0 &&
                teclaPresionada == 0;

                if (sePresionoUnaTeclaNoAdmitida || comienzaPorCero) {
                    evento.preventDefault(); 
                }
                
            });
        }
    }

    useEffect(() => {
        handleInput("documento")
        handleInput("celular")       
    })

    return (
        <React.Fragment>
            {loader ? <Progress color={"#1F2A4E"}></Progress> : (
                <React.Fragment>
                    {regresar ? (
                        <Cobertura/>
                    ) : (
                    <React.Fragment>
                        {registro ? (
                            <React.Fragment>
                                <VerificarEmail email={email} />
                            </React.Fragment>
                        ) : (
                            <React.Fragment>

                                <div className="form-control">
                                    <label>Nombre <span>*</span>:</label>
                                    <input 
                                    className={error.Nombres ? "input-error" : null}
                                    type="text" 
                                    value={nombre} 
                                    placeholder="Nombre completo" 
                                    onChange={e => setNombre(e.target.value)}></input>                                                        
                                    { error.Nombres ? (
                                        <span className="error">
                                            <b>{error.Nombres}</b>
                                        </span>
                                    ) : null }
                                </div>
                                <div className="form-control">
                                    <label>Apellidos <span>*</span>:</label>
                                    <input 
                                    className={error.Apellidos ? "input-error" : null}
                                    type="text"
                                    placeholder="Apellidos" 
                                    value={apellidos} 
                                    onChange={e => setApellidos(e.target.value)}></input>
                                    { error.Apellidos ? (
                                        <span className="error">
                                            <b>{error.Apellidos}</b>
                                        </span>
                                    ) : null }
                                </div>
                                <div className="form-row">
                                    <div className="form-control first">
                                        <label>Tipo Doc. <span>*</span>:</label>
                                        <select defaultValue={tipoDoc}
                                            className={error.TipoDoc ? "input-error" : null}
                                            onChange={e => setTipoDoc(e.target.value)}>
                                            <option>Seleccione</option>
                                            <option value="CC">CC</option>
                                            <option value="CE">CE</option>
                                            {/* <option value="TI">TI</option>                                                 */}
                                        </select>
                                        { error.TipoDoc ? (
                                            <span className="error">
                                                <b>{error.TipoDoc}</b>
                                            </span>
                                        ) : null }
                                    </div>                                                
                                    <div className="form-control end">
                                        <label>Documento <span>*</span>:</label>
                                        <input 
                                        pattern="^[0-9]+"
                                        min="0"
                                        className={error.Doc ? "input-error" : null}
                                        type="number" 
                                        value={documento} 
                                        placeholder="Número de documento" 
                                        id="documento"
                                        onChange={e => setDocumento(e.target.value)} onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }></input>                                                        
                                        { error.Doc ? (
                                        <span className="error">
                                            <b>{error.Doc}</b>
                                        </span>
                                    ) : null }
                                    </div>
                                </div>
                                <div className="form-control">
                                    <label>Celular <span>*</span>:</label>
                                    <input 
                                    className={error.Telefono ? "input-error" : null}
                                    type="number" 
                                    placeholder="Celular" 
                                    pattern="^[0-9]+" 
                                    min="0"
                                    id="celular"
                                    value={celular} 
                                    onChange={e => setCelular(e.target.value)} 
                                    onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }></input>                                                        
                                    { error.Telefono ? (
                                        <span className="error">
                                            <b>{error.Telefono}</b>
                                        </span>
                                    ) : null }
                                </div>
                                <div className="form-control">
                                    <label>Correo <span>*</span>:</label>
                                    <input 
                                    className={error.Email ? "input-error" : null}
                                    type="email" 
                                    value={email} 
                                    placeholder="Dirección de correo electrónico" 
                                    onChange={e => setEmail(e.target.value)}></input>                                                        
                                    { error.Email ? (
                                        <span className="error">
                                            <b>{error.Email}</b>
                                        </span>
                                    ) : null }
                                </div>
                                <div className="form-control">
                                    <label>Contraseña <span>*</span>:</label>
                                    <input 
                                    className={error.Clave ? "input-error" : null}
                                    type={hidden ? "text" : "password"} 
                                    value={contrasena} 
                                    placeholder="Contraseña" 
                                    onChange={e => setContrasena(e.target.value)}></input>                                                        
                                    {hidden ? (
                                        <div>
                                            <img className="button-hidden" src={visibility} onClick={ e => setHidden(false)} alt="icon minus" width="15px" height="15px"></img>
                                        </div>
                                    ) : (
                                        <div>
                                            <img className="button-hidden" src={novisibility} onClick={ e => setHidden(true)} alt="icon minus" width="15px" height="15px"></img>
                                        </div>
                                    )}
                                    { error.Clave ? (
                                        <span className="error">
                                            <b>{error.Clave}</b>
                                        </span>
                                    ) : null }
                                </div>
                                <div className="form-check">
                                    <input type="checkbox" defaultValue="true" id="terminos_condiciones" name="terminos_condiciones" onClick={e => handleCheckbox('terminos_condiciones')}></input>
                                    <label htmlFor="terminos_condiciones">
                                        <span></span>
                                        Acepto <a onClick={() => setPolitica(2)}>solicitud de autorizacion de datos personales</a> y <a onClick={() => setPolitica(1)}>términos y condiciones</a> 
                                    </label>
                                </div>
                                { error.TyC ? (
                                    <span className="error">
                                        <b>* Acepte autorización de datos personales y términos y condiciones</b>
                                    </span>
                                ) : null}

                                { errorg ? (
                                    <span className="error">
                                        <b>* {errorg}</b>
                                    </span>
                                ) : null }
                                
                                <button type="button" className="btn-large" onClick={() => handleRegistro()}>Registrarse</button>
                                <button type="button" className="btn-large outline" onClick={() => handleBack()}>Regresar</button>
                                {politica > 0 ? (<PoliticaModal politica={politica} handlePolitica={handlePolitica}/>): (null)}
                                
                            </React.Fragment>
                        
                        )}
                    </React.Fragment>
                    
                )}
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default withRouter(DatosPersonales);

