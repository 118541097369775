import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import Navbar from '../navbar/Navbar';
import Categoria from '../navbar/Categoria';
import Producto from './Producto';
import Sub_nav from '../navbar/Sub_nav'
import Footer from '../footer/Footer';
import Search from '../util/Search';

import visibility from '../../assets/icon/visibility.svg';
import SucursalModal from '../modal/SucursalModal';


const Catalogo = (props) => {

    const [search, setSearch] = useState('');
    const [categoria, setCategoria] = useState();
    const [categoriaId, setCategoriaId] = useState();

    const handleCategoria = item => {
        // setSearch('')
        setCategoria(item);
    }
    
    useEffect(() => {
        window.scrollTo(0, 0);
        if(props.match.params.categoria){
            var urlCategoria = props.match.params.categoria;
            var categoriaArray = urlCategoria.split("-");
            var categoryId = categoriaArray[categoriaArray.length - 1];
            setCategoriaId(categoryId);
        }else{
            setCategoriaId(15);
        }

        let url = new URL(window.location.href);
        let params = new URLSearchParams(url.search);
        const q = params.get('q');

        if(q){
            setSearch(q); 
        }
        
    }, [props.match.params.categoria])

    return (
        <React.Fragment>
            <div className="navbar">
                <Navbar item={1} />
                <Search search={search} updateSearch={setSearch}/>                                            
                <Sub_nav/>
                <Categoria categoriaId={categoriaId} handleCategoria={handleCategoria}/>
            </div>

            <div className="layout">
                <Producto 
                    tipo={1} 
                    categoriaId={categoriaId}
                    categoria={categoria} 
                    search={search}/>
            </div>
            
           

            <SucursalModal mayorEdad={true} />

            <Footer />
        </React.Fragment>
    )
}

export default withRouter(Catalogo);