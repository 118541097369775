import React, {useEffect} from 'react'

import close_grey from '../../assets/icon/close_grey.svg'

const Search = (props) => {
    
    const deleteSearch = item => {
        props.updateSearch('');
    }

    useEffect(() => {
        document.getElementById("search").focus();
    })

    return (
        <div className="input-search">
            <input 
                type="text" 
                placeholder="Buscar producto..."
                name="search"
                id="search"
                onChange={(e) => props.updateSearch(e.target.value)}
                value={props.search}>
            </input>
            <div>
                { props.search.length > 0 ? (
                    <img className="close-search" onClick={() => deleteSearch()} src={close_grey} alt="icon minus" width="15px" height="15px"></img>
                ) : null }
                
            </div>
        </div>
    )
}

export default Search
