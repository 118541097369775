import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import {connect} from "react-redux";

// COMPONENTES
import Menu from './Menu';
import CoberturaModal from '../modal/CoberturaModal';

// REDUX
import {selectActiveUser} from '../../store/usuario/reducer';
import {selectActiveCarrito} from '../../store/carrito/reducer';

// IMAGENES
import anddress from '../../assets/icon/address.svg';
import perfil from '../../assets/icon/perfil.svg';
import carrito from '../../assets/icon/carrito.svg';
import Auth from '../../helpers/Auth';

const Sub_nav = (props) => {

    const [cantidad, setCantidad] = useState(0);
    const [modalCobertura, setModalCobertura] = useState(false);
    const [municipio, setMunicipio] = useState(false);

    const handleMenu= () => {
        document.querySelector(".menu").classList.toggle("show-menu");  
    }

    const handleRedirect = item => {
        props.history.push(item);
    }

    const getCantidad = () => {
        var cant = 0;
        let cart = props.carrito || {};
        Object.keys(cart).map((item, i) => {
            cant += cart[item].cantidad;
        })
        setCantidad(cant);
    }

    const showCobertura = () => {
        if(!Auth.getToken()){
            setModalCobertura(true);
        }
    } 

    useEffect(() => {
        var ubicacion = Auth.getUbicacion();
        if(Object.keys(ubicacion).length > 0)
            setMunicipio(ubicacion.municipio);
        else {
            setModalCobertura(true);
        }
            

        getCantidad();
    }, [props.carrito])

    return (
        <React.Fragment>
            <div className="navmenu">
                <ul id="menu">
                    <li className="location">
                        <a  className="tab"
                            onClick={ () => showCobertura()}>
                            <img src={anddress} alt="icon menu" width="20px" height="20px"></img>
                            { municipio ? municipio : "SIN UBICACIÓN" }
                        </a>
                    </li>
                    <li className="profile">
                        <a  className="tab"
                            onClick={ () => handleMenu()}
                            style={{backgroundColor: props.color, opacity: "0.8"}}>
                            <img src={perfil} alt="icon menu" width="15px" height="15px"></img>
                            {props.usuario ? props.usuario.nombre + " " + props.usuario.apellido : "Ingresar / Registrarse"}
                        </a>
                    </li>
                    <li>
                        <a className="tab cart"
                            onClick={ () => handleRedirect('/carrito')}>
                            <img src={carrito} alt="icon menu" width="15px" height="15px"></img>
                            {cantidad > 0 ? (
                                <span className="cant">{cantidad}</span>
                            ): null}
                        </a>
                    </li>                                 
                </ul>
            </div> 
            <Menu history={props.history}/> 
            { modalCobertura ? (<CoberturaModal updateMunicipio={setMunicipio} showModal={setModalCobertura} />) : null}
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
      usuario: selectActiveUser(state),
      carrito: selectActiveCarrito(state)
    }
}
  

export default  withRouter(connect(mapStateToProps) (Sub_nav));