import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import firebase from "../../constants/firebase";
import * as Constants from "../../constants/global";
import axios from "axios";
import Auth from "../../helpers/Auth";
import Progress from "../util/Progress";

// COMPONENTES
import Maqueta from "../maqueta/Maqueta";
import VerificarEmail from "./VerificarEmail";

//REDUX
import ActualizarUsuario from "../../store/usuario/action";

// IMAGENES
import marcas from "../../assets/img/logo_login.png";
import novisibility from "../../assets/icon/hidden.svg";
import visibility from "../../assets/icon/visibility.svg";
import back from "../../assets/icon/back.svg";

const Login = (props) => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [errorg, setErrorg] = React.useState("");
  const [errors, setErrors] = React.useState("");
  const [hidden, setHidden] = React.useState(false);
  const [slide, setSlide] = React.useState(false);
  const [verificar, setVerificar] = React.useState(false);

  const handleRegistrar = () => {
    props.history.push("/cobertura");
  };

  const handleRecuperarclave = () => {
    props.history.push("/recuperar-clave");
  };

  const handleSubmit = (event) => {
    setLoader(true);
    let url = Constants.URL_BASE + Constants.WSAUTH;
    axios
      .post(url, {
        email: email,
        clave: password,
      })
      .then((res) => {
        setLoader(false);
        setHidden(false);
        setSlide(true);
        if (res.data) {
          firebase
            .analytics()
            .logEvent(
              "login",
              res.data.cliente.doc + "-" + res.data.cliente.email
            );
          props.ActualizarUsuario(res.data.cliente);
          Auth.setToken(res.data.token);
          Auth.setUbicacion({
            departamentoId: res.data.cliente.direcciones[0].departamentoId,
            departamento: res.data.cliente.direcciones[0].departamento,
            municipioId: res.data.cliente.direcciones[0].municipioId,
            municipio: res.data.cliente.direcciones[0].municipio,
          });
          props.history.replace("/");
        }
      })
      .catch((err) => {
        setLoader(false);

        if (err.response.data.errors) {
          setErrors(err.response.data.errors);
        }

        if (err.response.data.error) {
          setErrorg(err.response.data.error);

          if (err.response.data.code == "NO_VERIFICADO") {
            setVerificar(true);
          }
        }
      });
    event.preventDefault();
  };

  useEffect(() => {
    if (!slide) {
      if (document.querySelector(".login-content")) {
        document.querySelector(".login-content").classList.add("show");

        setTimeout(function () {
          setSlide(true);
          if (document.querySelector(".login-content"))
            document.querySelector(".login-content").classList.remove("show");
        }, 4000);
      }
    }
  }, []);

  // VISTAS
  return (
    <div className="login">
      <div className="login-content">
        <Maqueta />
        <div className="login-form" id="login-form">
          <a className="btn-back" href="/">
            <img src={back} alt="icon back" width="15px" height="15px"></img>
            <p>Volver a la tienda</p>
          </a>

          <div className="main">
            {verificar ? (
              <form>
                <VerificarEmail email={email} />
              </form>
            ) : (
              <React.Fragment>
                <div className="logo-img">
                  <img src={marcas} alt="logo app"></img>
                </div>
                {loader ? (
                  <Progress color={"#1F2A4E"} />
                ) : (
                  <form onSubmit={handleSubmit}>
                    <React.Fragment>
                      <div
                        style={{ marginTop: "2rem" }}
                        className="form-control"
                      >
                        <label>
                          Correo o Identificación <span>*</span>:
                        </label>
                        <input
                          className={errors.Email ? "input-error" : null}
                          type="text"
                          placeholder="Correo o Identificación"
                          name="email"
                          id="email"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                        ></input>
                        {errors.Email ? (
                          <span className="error">
                            <b>{errors.Email}</b>
                          </span>
                        ) : null}
                      </div>
                      <div className="form-control">
                        <label>
                          Contraseña <span>*</span>:
                        </label>
                        <input
                          className={errors.Clave ? "input-error" : null}
                          type={hidden ? "text" : "password"}
                          placeholder="Contraseña"
                          name="password"
                          id="password"
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                        ></input>
                        {hidden ? (
                          <div>
                            <img
                              className="button-hidden"
                              src={visibility}
                              onClick={(e) => setHidden(false)}
                              alt="icon minus"
                              width="15px"
                              height="15px"
                            ></img>
                          </div>
                        ) : (
                          <div>
                            <img
                              className="button-hidden"
                              src={novisibility}
                              onClick={(e) => setHidden(true)}
                              alt="icon minus"
                              width="15px"
                              height="15px"
                            ></img>
                          </div>
                        )}

                        {errors.Clave ? (
                          <span className="error">
                            <b>{errors.Clave}</b>
                          </span>
                        ) : null}
                      </div>

                      {errorg.length > 0 ? (
                        <span className="error">
                          <p>{errorg}</p>
                        </span>
                      ) : null}

                      <div className="form-link">
                        <a onClick={(e) => handleRecuperarclave()}>
                          ¿Olvidé mi contraseña?
                        </a>
                      </div>

                      <button type="submit" className="btn-large ">
                        Ingresar
                      </button>
                      <button
                        type="button"
                        className="btn-large outline"
                        onClick={() => handleRegistrar()}
                      >
                        Registrarse
                      </button>

                      <div className="form-link">
                        {/* <a href="/manual" target="_blank">
                          Conocer más
                        </a> */}
                      </div>
                    </React.Fragment>
                  </form>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(connect(null, { ActualizarUsuario })(Login));
