import React, {useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import { HashLink as Link } from 'react-router-hash-link';
import $ from 'jquery'; 
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


import marcas from '../../assets/img/marcas.png';
import pasos from '../../assets/manual/pasos.png';
import burbujafondo from '../../assets/manual/burbuja_fondo.png';
import burbujader from '../../assets/manual/burbuja_der_fondo.png';
import ondafondo from '../../assets/manual/onda_fondo.png';

import registro1 from '../../assets/manual/registro/registro_1.png';
import registro2 from '../../assets/manual/registro/registro_2.png';
import registro3 from '../../assets/manual/registro/registro_3.png';
import registro4 from '../../assets/manual/registro/registro_4.png';
import registro5 from '../../assets/manual/registro/registro_5.png';

import login1 from '../../assets/manual/session/login_1.png';
import login2 from '../../assets/manual/session/login_2.png';

import producto1 from '../../assets/manual/producto/producto_1.png';
import producto2 from '../../assets/manual/producto/producto_2.png';
import producto3 from '../../assets/manual/producto/producto_3.png';
import producto4 from '../../assets/manual/producto/producto_4.png';
import producto5 from '../../assets/manual/producto/producto_5.png';
import producto6 from '../../assets/manual/producto/producto_6.png';
import producto7 from '../../assets/manual/producto/producto_7.png';

import checkout1 from '../../assets/manual/pedido/checkout_1.png';
import checkout2 from '../../assets/manual/pedido/checkout_2.png';
import checkout3 from '../../assets/manual/pedido/checkout_3.png';

import descarga from '../../assets/manual/descarga.png';

import entregas from '../../assets/manual/entregas_img.png';

import logo_footer from '../../assets/manual/logo_footer.png';

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)  

const LandingPage = (porps) => {

    const [active, setActive] = useState(1);
    const refRegistro = useRef(null);
    const refSesion = useRef(null);
    const refProducto = useRef(null);
    const refPedido = useRef(null);

    const options = {
        margin: 30,
        responsiveClass: true,
        dots: true,
        autoplay: false,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 2,
            },
            400: {
                items: 2,
            },
            600: {
                items: 2,
            },
            700: {
                items: 3,
            },
            1000: {
                items: 4,
    
            }
        },
    };

    const handleScroll = (item) => {
        setActive(item);
        if(item == 1)
            scrollToRef(refRegistro);
        if(item == 2)
            scrollToRef(refSesion);
        if(item == 3)
            scrollToRef(refProducto);
        if(item == 4)
            scrollToRef(refPedido);
    }

    return (
        <React.Fragment>
           <div className="manual">
                <div className="manual-logo">
                    <img src={marcas} alt="logo app"></img>
                </div>
                <div className="manual-card">
                    <div className="manual-menu">
                        <ul>
                            <li>
                                <a className={active == 1 ? 'active' : null} onClick={() => handleScroll(1)}>Registro</a>
                            </li>
                            <li>
                                <a className={active == 2 ? 'active' : null} onClick={() => handleScroll(2)}>Inicio sesión</a>
                            </li>
                            <li>
                                <a className={active == 3 ? 'active' : null} onClick={() => handleScroll(3)}>Selección de producto</a>
                            </li>
                            <li>
                                <a className={active == 4 ? 'active' : null} onClick={() => handleScroll(4)}>Confirmación de pedido</a>
                            </li>
                        </ul>
                    </div>

                    <div className="manul-fondo-left">
                        <img className="fnd-1" src={burbujafondo} alt="logo app"></img>
                        <img className="fnd-3" src={ondafondo} alt="logo app"></img>
                    </div>

                    <div className="manual-fondo-rigth">
                        <img className="fnd-2" src={burbujader} alt="logo app"></img>
                    </div>

                    <div className="manual-pasos">
                        <div className="pasos-img">
                            <img src={pasos} alt="logo app"></img>
                        </div>
                    </div>

                    <div className="manual-registro" ref={refRegistro}>
                        {/* <div className="ma-re-fondo">
                            <div className="izq" style={bgBurbujaFondo}></div>
                        </div> */}
                        <div className="ma-re-content">
                            <div className="ma-re-titulo">
                                <h2>1. Registro de nuevo usuario</h2>
                            </div>
                            <div className="ma-re-slide">
                                <OwlCarousel
                                    className="owl-theme"
                                    {...options}>
                                    <div className="item">
                                        <img src={registro1} alt="logo app"></img>
                                        <p>Validación de cobertura</p>
                                    </div>
                                    <div className="item">
                                        <img src={registro2} alt="logo app"></img>
                                        <p>Cobertura disponible</p>
                                    </div>
                                    <div className="item">
                                        <img src={registro3} alt="logo app"></img>
                                        <p>Datos de usuario</p>
                                    </div>
                                    <div className="item">
                                        <img src={registro4} alt="logo app"></img>
                                        <p>Código de verificación</p>
                                    </div>
                                    <div className="item">
                                        <img src={registro5} alt="logo app"></img>
                                        <p>Código de verificación</p>
                                    </div>
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>

                    <div className="manual-session" ref={refSesion}>
                        {/* <div className="ma-re-fondo">
                            <div className="izq" style={bgBurbujaFondo}></div>
                            <div className="der" style={bgBurbujaFondo}></div>
                        </div> */}
                        <div className="ma-re-content">
                            <div className="ma-re-titulo">
                                <h2>2. Inicio de sesión</h2>
                            </div>
                            <div className="ma-re-slide">
                                <OwlCarousel
                                    className="owl-theme"
                                    {...options}>
                                    <div className="item">
                                        <img src={login1} alt="logo app"></img>
                                    </div>
                                    <div className="item">
                                        <img src={login2} alt="logo app"></img>
                                    </div>
                                </OwlCarousel>
                                <p>Ingresa tus datos, puedes ingresarar con tu correo o número de identificación.</p>
                            </div>
                        </div>
                    </div>

                    <div className="manual-producto" ref={refProducto}>
                        {/* <div className="ma-re-fondo">
                            <div className="izq" style={bgBurbujaFondo}></div>
                        </div> */}
                        <div className="ma-re-content">
                            <div className="ma-re-titulo">
                                <h2>3. Selección de productos</h2>
                            </div>
                            <div className="ma-re-slide">
                                <OwlCarousel
                                    className="owl-theme"
                                    {...options}>
                                    <div className="item">
                                        <img src={producto1} alt="logo app"></img>
                                    </div>
                                    <div className="item">
                                        <img src={producto2} alt="logo app"></img>
                                    </div>
                                    <div className="item">
                                        <img src={producto3} alt="logo app"></img>
                                    </div>
                                    <div className="item">
                                        <img src={producto4} alt="logo app"></img>
                                    </div>
                                    {/* <div className="item">
                                        <img src={producto5} alt="logo app"></img>
                                    </div>
                                    <div className="item">
                                        <img src={producto6} alt="logo app"></img>
                                    </div>
                                    <div className="item">
                                        <img src={producto7} alt="logo app"></img>
                                    </div> */}
                                </OwlCarousel>
                                <p>Selecciona los productos y las cantidades, para confirmar tu pedido presione en el icono del carrito.</p>
                            </div>
                        </div>
                    </div>

                    <div className="manual-pedido" ref={refPedido}>
                        {/* <div className="ma-re-fondo">
                            <div className="izq" style={bgBurbujaFondo}></div>
                        </div> */}
                        <div className="ma-re-content">
                            <div className="ma-re-titulo">
                                <h2>4. Confirmación de pedido</h2>
                            </div>
                            <div className="ma-re-slide">
                                <OwlCarousel
                                    className="owl-theme"
                                    {...options}>
                                    <div className="item">
                                        <img src={checkout1} alt="logo app"></img>
                                    </div>
                                    <div className="item">
                                        <img src={checkout2} alt="logo app"></img>
                                    </div>
                                    <div className="item">
                                        <img src={checkout3} alt="logo app"></img>
                                    </div>
                                </OwlCarousel>
                                <p>Verifca los datos correspondientes a tu pedido y por último confirma para finalizarlo.</p>
                            </div>
                        </div>
                    </div>

                    <div className="manual-entrega">
                        <div className="ma-re-titulo">
                            <h2>5. Entregas</h2>
                        </div>
                        <div className="entrega-content">
                            <div className="entrega-texto">
                                <p>Podrás hacer pedidos a cualquier hora y día de la semana, para que tu pedido sea entregado al día siguiente, debes realizarlo antes de las 4:00 pm. Si realizas el pedido después de las 4:00 pm será entregado dos días después.</p>
                                <p><b>Tus pedidos serán entregados de lunes a sábado. Ten en cuenta que los pedidos realizados el sábado, serán entregados el lunes.</b></p>
                                <p>Los domingos no se entregan pedidos.</p>
                            </div>
                            <div className="entrega-img">
                                <img src={entregas} alt="logo app"></img>
                            </div>
                        </div>
                    </div>

                    {/* <div className="manual-descarga">
                        <a>
                            <img src={descarga} alt="logo app"></img>
                        </a>
                    </div> */}

                    <div className="manual-footer">
                        <div className="ma-fo-content">
                            <div className="ma-fo-img">
                                <img src={logo_footer} alt="logo app"></img>
                            </div>
                            <div className="ma-fo-texto">
                                {/*<p>Linea de transparencia 01-8000-123400</p>*/}
                                <p>Linea Gratuita Nacional de Servicio al cliente 0180005159 59 Bogotá: 2204300 Medellín: 2655151</p>
                            </div>
                        </div>
                    </div>

                   
                </div>
                
           </div>
        </React.Fragment>
    )
}


export default LandingPage;