import React, { useState, useEffect } from 'react'
import * as Constants from '../../constants/global'
import axios from 'axios'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import noimage from '../../assets/img/logos_pstbn-02.png'

const Marca = () => {

    const [seccion, setSeccion] = useState();

    const options = {
        margin: 5,
        responsiveClass: true,
        dots: true,
        autoplay: false,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 3,
            },
            400: {
                items: 4,
            },
            600: {
                items: 5,
            },
            700: {
                items: 6,
            },
            1000: {
                items: 7,
    
            }
        },
    };

    useEffect(() => {
        const seccionData = () => {
            let url = Constants.URL_BASE + Constants.WSSECCION;
            axios.get(url)
            .then(res => {  
                setSeccion(res.data);
            });
        };
        seccionData();
    }, [])

    return (
        <React.Fragment>
            <div className="marca">
                {seccion ? (
                    // <OwlCarousel
                    //     className="owl-theme"
                    //     {...options}>
                    <React.Fragment>
                        {seccion.map((item, i)=>(
                            <div className="item">
                                <div key={i} value={item.id} style={{backgroundColor: item.color}}> 
                                    <a href={"/seccion/" + item.nombre}>
                                        <img 
                                            className="img-marca"
                                            src={ Constants.URL_BASE +'logo/'+ item.logo.trim()}
                                            onError={(e)=>{e.target.onerror = null; e.target.src=noimage}}>
                                        </img>
                                    </a>
                                </div>
                            </div>
                        ))}
                    </React.Fragment>
                        
                    // </OwlCarousel>
                ): null}
            </div>
        </React.Fragment>
    )
}

export default Marca
