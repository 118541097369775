import React, { useState, useEffect } from 'react';
import {connect} from "react-redux";
import { withRouter } from "react-router-dom";
import * as Constants from '../../constants/global';
import axios from 'axios';

// REDUX
import ActualizarUsuario from '../../store/usuario/action';
import {selectActiveUser} from '../../store/usuario/reducer';

const CheckOut = (props) => {

    const [fechaEntrega, setFechaEntrega] = useState();

    const verificarFecha = () => {
        let cliente = props.usuario
        let url = Constants.URL_BASE + Constants.WSFECHAENTREGA;
        axios.get(url)
        .then((response)=>{
            setFechaEntrega(response.data.texto);
            cliente.fecha=response.data.fecha;
            props.ActualizarUsuario(cliente);
        });
    }

    const handleObservacion = (value) =>{
        let cliente = props.usuario
        cliente.observacion=value
        props.ActualizarUsuario(cliente)
    }

    useEffect(() => {
        verificarFecha()
    }, [])

    return (
        <React.Fragment>
            <br/>
            <div className="check-content">
                <div className="item" style={{"padding-top":"15px"}}> 
                    <p className="opc">Cliente:</p>
                    <p className="val">{
                        props.usuario ? ( <React.Fragment>{props.usuario.nombre}  {props.usuario.apellido }</React.Fragment>)
                        : ('')
                    }</p>
                </div>
                <hr style={Constants.style}/>
                <div className="item" style={{"padding-top":"15px"}}>
                    <p className="opc">Dirección:</p>
                    <p className="val">{props.usuario ? ( 
                        props.usuario.direcciones[0].direccion + ', ' + 
                        (props.usuario.direcciones[0].referencia ? props.usuario.direcciones[0].referencia + ', ': '') +
                        props.usuario.direcciones[0].municipio + ' ' +
                        props.usuario.direcciones[0].departamento
                        ) : (null)}</p>
                </div>
                {/* <hr style={Constants.style}/>
                <div className="item" style={{"padding-top":"15px"}}>
                    <p className="opc">Fecha de entrega:</p>
                    <p className="val">{fechaEntrega}</p>
                </div>
                <hr style={Constants.style}/>
                <div className="item" style={{"padding-top":"15px"}}>
                    <p className="opc">Método de pago:</p>
                    <p className="val">Contraentrega, paga cuando recibas</p>
                </div> */}
                <hr style={Constants.style}/>
                <div className="item" style={{"padding-top":"15px"}}>
                    <input className="input" onChange={e => handleObservacion(e.target.value)} placeholder="Observaciones"></input>
                </div>
            </div>
        </React.Fragment>
    )
}


const mapStateToProps = (state) => {
    return {
      usuario: selectActiveUser(state)
    }
}

export default withRouter(connect(mapStateToProps, {ActualizarUsuario}) (CheckOut));