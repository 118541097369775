import React , { useState, useEffect, useRef } from 'react';
import Pagina from '../util/Pagina';
import Navbar from '../navbar/Navbar';
import Sub_nav from '../navbar/Sub_nav'
import Footer from '../footer/Footer';

const PreguntasFrecuentes = (porps) => {

    return (
        <React.Fragment>
            <div className="content-carrito">
                <div className="navbar navcarrito">
                    <Navbar item={1} />                                                                      
                    <Sub_nav />
                </div>

                <div className="layout">
                    <div className="pagina">
                        <Pagina tipo={3} />
                    </div>
                </div>
            </div>

            <Footer />
        </React.Fragment>
    )
}


export default PreguntasFrecuentes;