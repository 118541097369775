import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import marcas from "../../assets/img/logo_en_slider.png";
import slidefooter from "../../assets/img/slidefooter.png";
import slidebeneficios from "../../assets/img/slidebeneficios.png";
import slidefondo from "../../assets/banner/new-banner-login.jpg";

const Maqueta = (props) => {
  var sectionStyle = {
    backgroundSize: "100% 100%",
    backgroundImage: "url(" + slidefondo + ")",
    animationName: "bounceInDown",
    animationDuration: "1s",
  };

  return (
    <React.Fragment>
      <div className="splash" id="splash" style={sectionStyle}>
        {/* <div className="splash-titulo">
                    <h3>En tan solo 4 pasos podrás tener los sabores de Postobón en la puerta de tu casa, sin costo de envío.</h3>
                </div> */}
        {/* <div className="logo-img">
          <img src={marcas} alt="logo app"></img>
        </div> */}
        {/* <div className="maqueta-img">
          <img src={slidefondo} alt="logo app"></img>
        </div> */}
        {/* <div className="splash-link">
                    <a href="/manual" target="_blank">Conocer más</a>
                </div> */}

        {/* <div className="slide-footer">
                    <div className="footer-img">
                        <img src={slidefooter} alt="logo app"></img>
                    </div>
                </div> */}
      </div>
    </React.Fragment>
  );
};

export default withRouter(Maqueta);
