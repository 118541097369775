import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import MobileStoreButton from 'react-mobile-store-button';

import logo  from '../../assets/img/fondoEmpleados.PNG';
import downloadapp  from '../../assets/img/downloadapp.png';
import logo_cw from '../../assets/img/Logo_Cw_Blanco.png';
import facebook from '../../assets/icon/facebook.svg';
import twitter from '../../assets/icon/twitter.svg';
import instagram from '../../assets/icon/instagram.svg';
import youtube from '../../assets/icon/youtube.svg';
import linkedin from '../../assets/icon/linkedin.svg';

const Footer = (props) => {
    const handleRedirect = item => {
        props.history.push(item);
    }
    return (
        <div className="footer"  style={{backgroundColor: props.color}}>
            <div className="content">
                <div className="logo">
                    <div className="img-logo">
                        <img src={logo}/>
                    </div>
                    {/* <a href="market://details?id=com.celuweb.postobonDos" className="img-app">
                        <img src={downloadapp}/>
                    </a> */}
                    {/* <div className="download-app">
                        <MobileStoreButton
                        store="ios"
                        url={"https://apps.apple.com/co/app/pedidos-postobon/id1511003716"}
                        linkProps={{ title: 'iOS Store Button' }}
                        />
                        <div className="android">
                            <MobileStoreButton
                            store="android"
                            url={"https://play.google.com/store/apps/details?id=com.celuweb.postobonDos&hl=es_CO"}
                            linkProps={{ title: 'Android Store Button' }}
                            />
                        </div>
                         
                        
                    </div> */}
                    
                </div>
                <div className="links">
                    <ul>
                        
                        <li><a onClick={() => handleRedirect('/politicas-datos')}>Pólitica de tratamiento de datos</a></li>
                        <li><a onClick={() => handleRedirect('/terminos-y-condiciones')}>Términos y condiciones de uso</a></li>
                        <li><a onClick={() => handleRedirect('/garantias-y-devoluciones')}>Garantía, devoluciones y derecho de retracto.</a></li>
                        <li><a onClick={() => handleRedirect('/disposiciones-privacidad-seguridad')}>Disposiciones sobre privacidad y seguridad de la información. </a></li>
                    </ul>
                </div>
                <div className="redes">
                    
                    <div>
                        <li><a style={{color:'white'}} href="http://www.fondogloria.com/" target="_blank"><b>www.fondogloria.com</b></a></li>
                        <li><a style={{color:'white'}} href="http://www.fondogloria.com/" target="_blank">Contáctenos</a></li>
                        <li><a style={{color:'white'}} href="#" target="_blank">1-5462400 Ext: 2005</a></li>
                        <li><a style={{color:'white'}} href="#" target="_blank">3104766951</a></li>
                        <li><a style={{color:'white'}} href="#" target="_blank">3102734893</a></li>
                    </div>
                </div>
            </div>
           
        </div>
    )
}

export default withRouter(Footer);