import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from "react-router-dom";
import {connect} from "react-redux";
import * as Constants from '../../constants/global';
import axios from 'axios';
import Auth from '../../helpers/Auth';

import Progress from '../util/Progress';
import AlertModal from '../modal/AlertModal';
import CardProducto from '../util/CardProducto';

//REDUX
import ActualizarCarrito from '../../store/carrito/action';
import {selectActiveCarrito} from '../../store/carrito/reducer';
import {selectActiveUser} from '../../store/usuario/reducer';


const Producto = (props) => {

    const [alerta, setAlerta] = useState();
    const [loader, setLoader] = React.useState(false);
    const [productos, setProductos] = useState([]);

    const loadValidacion = () => {
        var url = '';
        // var cliente = props.usuario;
        // if(cliente.fechaMayorEdad){
        //     url += "&mayor=true";
        // }else{
        //     url += "&mayor=false";
        // }

        // if(cliente.fechaMayor14){
        //     url += "&catorce=true";
        // }else{
        //     url += "&catorce=false";
        // }
        url += "&mayor=true";
        url += "&catorce=true";

        return url;
    }

    // TRAE TODOS LOS PRODUCTOS
    const loadProductos = async (tipo) => {
        let url = Constants.URL_BASE + Constants.WSPRODUCTOS
        if(props.search){
            url += "?buscar=" + props.search;
            url += loadValidacion();
        } else if(props.categoriaId){
            url += "?categoriaId=" + props.categoriaId;
            url += loadValidacion();
        }

        var ubicacion = Auth.getUbicacion();

        if(Object.keys(ubicacion).length > 0) {
            url += "&DepartamentoId="+ ubicacion.departamentoId +"&MunicipioId=" +  ubicacion.municipioId;
        }

        if(tipo == 1){
            if(productos){
                if(productos.now < productos.next){
                    url += "?page=" + productos.next;
                }
            }
        }
        
        axios.get(url)
        .then(res => {
            setLoader(false);
            setProductos(res.data);
        })
        .catch(err => {
            setLoader(false);
        });
    }

    const cargarMas = () => {
        loadProductos(1);
    }

    // SE EJECUTA CUANDO CAMBIA CATEGORIA O BUSQUEDA
    useEffect(() => {
        setLoader(true);
        if(props.categoriaId){
            loadProductos(0);
        }
    }, [props.categoriaId, props.search])

    // VISTA
    return (
        <div className="pedido-list">
            { loader ? <Progress color={"#1F2A4E"} /> : (
                <React.Fragment>
                    {productos.length > 0 ? (
                        <React.Fragment>
                            <h1 className="titulo-lista"><b>Selecciona los productos que deseas.</b></h1>
                            {/* <h4 className="titulo-lista">Si haces tu pedido antes de las 4pm será entregado mañana</h4> */}
                            {/* { props.categoriaId == 6 ? (
                                <div className="alertas">
                                    <p>CONTENIDO ELEVADO EN CAFEÍNA 32MG/100 ML. LA BEBIDA ENERGIZANTE NO PREVIENE LOS EFECTOS GENERADOS POR EL CONSUMO DE BEBIDAS ALCOHÓLICAS. NO SE RECOMIENDA EL CONSUMO DE BEBIDAS ENERGIZANTES CON BEBIDAS ALCOHÓLICAS. ESTE PRODUCTO SOLO PODRÁ SER COMERCIALIZADO, EXPENDIDO Y DIRIGIDO A POBLACIÓN MAYOR DE 14 AÑOS. ESTE PRODUCTO NO ES RECOMENDADO PARA PERSONAS SENSIBLES A LA CAFEÍNA (ART. 13 – RESOLUCIÓN 4150 DE 2009).</p>
                                </div>
                            ) : null }

                            { props.categoriaId == 5 ? (
                                <div className="alertas">
                                    <p>EL EXCESO DE ALCOHOL ES PERJUDICIAL PARA LA SALUD. LEY 30 DE 1986. PROHÍBASE EL EXPENDIO DE BEBIDAS EMBRIAGANTES A MENORES DE EDAD. LEY 124 DE 1994.</p>
                                </div>
                            ) : null } */}
                           
                        </React.Fragment> 
                    ) : (
                        <h2 className="titulo-lista"><b>No se encontraron productos</b></h2>
                    )}
                    
                    {productos.length > 0 ? (
                        <React.Fragment>
                            { props.tipo == 2 ? (
                                null                                
                            ) : (
                                <div className="product-card">
                                    {productos.map((item, i) => (
                                        <CardProducto 
                                            key={i}
                                            producto={item}
                                            setAlerta={setAlerta}/>
                                    ))}
                                </div>
                            )}

                            <div className="load-mas">
                                {productos.now < productos.next ? (
                                    <button onClick={() => cargarMas()}>Cargar mas</button>
                                ) : null }
                            </div>
                            
                        </React.Fragment>
                    ) : null}
                </React.Fragment>
            )}
            { alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta}/>) : null}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      usuario: selectActiveUser(state),
      carrito: selectActiveCarrito(state)
    }
}

export default withRouter(connect(mapStateToProps, {ActualizarCarrito}) (Producto));