import React, {useState, useEffect} from 'react'
import Navbar from '../navbar/Navbar';
import CardProducto from '../util/CardProducto';
import Sub_nav from '../navbar/Sub_nav'
import Search from '../util/Search'
import Marca from '../navbar/Marca'
import Auth from '../../helpers/Auth'
import Footer from '../footer/Footer'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel'

import * as Constants from '../../constants/global'
import axios from 'axios'
import AlertModal from '../modal/AlertModal';

import noimage from '../../assets/img/logos_pstbn-02.png'


const Seccion = (props) => {
    const [alerta, setAlerta] = useState();
    const [seccion, setSeccion] = useState();
    const [banner, setBanner] = useState([]);
    const [productos, setProductos] = useState([]);
    const [search, setSearch] = useState('');

    const handleSearch = value => {
        setSearch(value);
    }

    const bannerData = (seccion) => {
        let url = Constants.URL_BASE + Constants.WSBANNER + "?seccionId=" + seccion;
        axios.get(url)
        .then(res => {
            setBanner(res.data);
        });
    };

    const seccionData = (seccion) => {
        let url = Constants.URL_BASE + Constants.WSSECCION + "?seccion=" + seccion;

        axios.get(url)
        .then(res => { 
            setSeccion(res.data[0]);
            seccionProducto(res.data[0].id);
            bannerData(res.data[0].id);
        })
        .catch(err => {
            window.location.href = "/";
        });
    };

    const seccionProducto = (id) => {
        let url = Constants.URL_BASE + Constants.WSSECCIONPRODUCTO + "?seccionId=" + id;

        if(search){
            url += "&buscar=" + search;
        }

        var ubicacion = Auth.getUbicacion();

        if(Object.keys(ubicacion).length > 0) {
            url += "&DepartamentoId="+ ubicacion.departamentoId +"&MunicipioId=" +  ubicacion.municipioId;
        }

        axios.get(url)
        .then(res => { 
            setProductos(res.data);
        });
    }

    const onClickItem = (item) => {
        if(banner[item].enlace != null)
            window.location.href = banner[item].enlace;
    }

    useEffect(() => {  
        window.scrollTo(0, 0);
        if(props.match.params.seccion){    
            seccionData(props.match.params.seccion);
        }
    }, [search])


    return (
        <React.Fragment>
            <div className="navbar" style={seccion ? {backgroundColor: seccion.color} : {}}>
                <Navbar item={1}  />
                <Search search={search} updateSearch={handleSearch} />
                <Sub_nav color={seccion ? seccion.color : null}/>
            </div>

            {seccion ? (
                <React.Fragment>
                <div className="section-header"  style={seccion ? {backgroundColor: seccion.color} : {}}>
                    <a href={"/" + seccion.nombre} >
                        <img 
                            className="img-marca"
                            src={ Constants.URL_BASE +'logo/'+ seccion.logo}
                            onError={(e)=>{e.target.onerror = null; e.target.src=noimage}}>
                        </img>
                    </a>        
                </div>
                
               
                <div className="section-banner">
                {!search && (
                    <Carousel 
                        showArrows={true} 
                        showThumbs={false} 
                        autoPlay={true}
                        infiniteLoop={true}
                        showStatus={false}
                        onClickItem={onClickItem} >
                        { banner.map((item, i) => (
                            <div> 
                                <img 
                                    className="img-marca"
                                    src={ Constants.URL_BASE +'banner/'+ item.imagen}
                                    onError={(e)=>{e.target.onerror = null; e.target.src=noimage}} />
                            </div>
                        ))}
                    </Carousel>
                )}
                </div>
              
                

                <div className="layout">
                    <div className="seccion">
                    
                        <React.Fragment>
                            
                           
                            {productos.length > 0 && (
                                <div className="pedido-list">
                                    <h1 className="titulo-lista"><b>Selecciona los productos que deseas.</b></h1>
                                    {/* <h4 className="titulo-lista">Si haces tu pedido antes de las 4pm será entregado mañana</h4> */}

                                    {/* { seccion.nombre == "CCC" ? (
                                        <div className="alertas">
                                            <p>EL EXCESO DE ALCOHOL ES PERJUDICIAL PARA LA SALUD. LEY 30 DE 1986. PROHÍBASE EL EXPENDIO DE BEBIDAS EMBRIAGANTES A MENORES DE EDAD. LEY 124 DE 1994.</p>
                                        </div>
                                    ) : null } */}
                                   
                                    <div className="product-card">
                                        {productos.map((item, i) => (
                                            <CardProducto 
                                                key={i}
                                                producto={item}
                                                setAlerta={setAlerta}
                                                color={seccion.color}/>
                                        ))}
                                    </div>
                                </div>
                            )}
                            <Marca />
                        </React.Fragment>
                
                    </div>
                </div>
                <Footer color={seccion ? seccion.color : null}/>
                </React.Fragment>
            ) : null}
            
            { alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta}/>) : null}
        </React.Fragment>
    )
}

export default Seccion
