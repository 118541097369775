import React, { useState, useEffect } from 'react';
import {connect} from "react-redux";
import * as Constants from '../../constants/global';
import axios from 'axios';
import Auth from '../../helpers/Auth';

import edit from '../../assets/icon/editar.ico';
import Sub_nav from '../navbar/Sub_nav'
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import Progress from '../util/Progress';
import EditModal from '../modal/EditModal' ;

// REDUX
import {selectActiveUser} from '../../store/usuario/reducer';

const Datos = (props) => {

    const [carrito, setCarrito] = useState({});
    const [cantidad, setCantidad] = useState(0);
    const [editar, setEditar] = useState(false);
    const [loader, setLoader] = useState(false);
    const [message, setMessage] = useState();
    const [valido, setValido] = useState(false);

    // let cliente = props.usuario

    const handleEdit = (state) => {
        if(state == 2){
            if(valido)
                setEditar(state);
        }else{
            setEditar(state);
        }
        
    }

    const getResponse = (response) => {
        if(response){
            setLoader(true)
            let clientUpdate = props.usuario
            let url = Constants.URL_BASE + Constants.WSACTUALIZARDATOS + clientUpdate.id
            axios.put(url, {
                nombres:clientUpdate.nombre,
                apellidos:clientUpdate.apellido,
                email:clientUpdate.email,
                clave:clientUpdate.clave,
                telefono:clientUpdate.telefono,                
            } ,{
                headers: {
                    Authorization: `Bearer ${clientUpdate.token}`
                }
            }).then((response)=>{
                setLoader(false)
                setMessage(response.data.message)
                setTimeout(()=>{
                    setMessage(null)
                },3000)
            }).catch((err)=>{
            })
        }
        setEditar(false)
    }

    const getCantidad = () => {
        var cant = 0;
        let cart = JSON.parse(localStorage.getItem('carrito')) || {};
        Object.keys(cart).map((item, i) => {
            cant += cart[item].cantidad;
        })
        setCantidad(cant);
    }


    useEffect(() => {
        setCarrito(JSON.parse(localStorage.getItem('carrito')) || {});
        getCantidad();
        const validarEdicion = () => {
            let url = Constants.URL_BASE + Constants.WSVALIDAREDICION + props.usuario.doc

            axios.get(url, {
                headers:{
                    Authorization: `Bearer ${Auth.getToken()}`
                }
            })
            .then(res => {
                if(res.data.valido)
                    setValido(res.data.valido);
            });
        };
        validarEdicion();
    }, [props.usuario])


    return (
        <React.Fragment>
            
            <div className="navbar navcarrito">
                <Navbar item={3} />
                <Sub_nav cantidad={cantidad}/>
            </div>
            <div className="layout">

                <div className="datos"> 
                    {message ? (<label>{message}</label>) : (null)}
                    {loader ? <Progress color={"#1F2A4E"}></Progress> : (
                        <div>
                            <div className="card">
                                <a className="edit" onClick={() => handleEdit(1)}>
                                    <img src={edit} alt="icon delete" width="30px" height="30px"></img>
                                </a>
                              
                                <h3>Datos personales</h3>
                                <div className="info">
                                    <p><b>Nombre:</b> {props.usuario.nombre + " " + props.usuario.apellido}</p>
                                    <p><b>Documento:</b> {props.usuario.tipoDoc + " " + props.usuario.doc}</p>
                                    <p><b>Correo:</b> {props.usuario.email}</p>
                                    <p><b>Teléfono:</b> {props.usuario.telefono}</p>
                                </div>
                                
                              
                            </div>
                            <br/><br/>
                            <div className="card">
                                {/* {valido ? ( */}
                                    <a style={{opacity: valido ? "1" : "0.3"}} className="edit" onClick={() => handleEdit(2)}>
                                        <img src={edit} alt="icon delete" width="30px" height="30px"></img>
                                    </a>
                                {/* ) : null } */}
                                <h3>Mi dirección</h3>
                                
                                <div className="info">
                                    {!valido ? (
                                        <p style={{color:"red"}}>Si quieres actualizar tu dirección, comunícate a la línea de servicio al cliente 01 8000 51 59 59</p>
                                    ) : null }
                                    <p><b>Dirección:</b> {props.usuario.direcciones ? props.usuario.direcciones[0].direccion : ""}</p>
                                    <p><b>Barrio:</b> {props.usuario.direcciones ? props.usuario.direcciones[0].referencia.split("-")[0]: ""}</p>
                                    <p><b>Referencia:</b> {props.usuario.direcciones ? props.usuario.direcciones[0].referencia.split("-")[1]: ""}</p>
                                    <p><b>Municipio:</b> {props.usuario.direcciones ? props.usuario.direcciones[0].municipio : ""}</p>
                                    <p><b>Departamento:</b> {props.usuario.direcciones ? props.usuario.direcciones[0].departamento : ""}</p>
                                </div>
                            </div>
                            <div className="panel-button">
                                <a className="seguir-comprando" onClick={e => props.history.push('/')}>
                                    Seguir comprando
                                </a>
                            </div>
                        </div>
                    )}
                </div>                 
            </div>
            
            {editar ? (<EditModal editar={editar} callback={getResponse.bind(this)} />): (null)}
            
            <Footer/>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
      usuario: selectActiveUser(state)
    }
}

export default connect(mapStateToProps) (Datos);