import React, { useEffect } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import {connect} from "react-redux";
import axios from 'axios';
import * as Constants from './constants/global';
import Auth from './helpers/Auth';

// COMPONENTS
import Login from './components/auth/Login';
import Catalogo from './components/pedido/Catalogo';
import Detalle from './components/pedido/Detalle';
import Carrito from './components/pedido/Carrito';
import Seccion from './components/pedido/Seccion';
import Pack from './components/pedido/Pack';
import Cobertura from './components/auth/Cobertura';
import Restablecer from './components/auth/Restablecer';
import NoCobertura from './components/auth/NoCobertura';
import Pedidos from './components/perfil/Pedidos';
import Datos from './components/perfil/Datos';
import Inicio from './components/paginas/Inicio';
import LandingPage from './components/paginas/LandingPage';
import PoliticaDatos from './components/paginas/PoliticaDatos';
import PreguntasFrecuentes from './components/paginas/PreguntasFrecuentes';

//REDUX
import ActualizarUsuario from './store/usuario/action';
import ActualizarCarrito from './store/carrito/action';

// SASS
import './styles/app.scss';

// AGREGA TOKEN AL ENCABEZADO
Auth.headerToken();

// COMPONENTES
const App = (props) => {

  const getUser = async () => {
    let url = Constants.URL_BASE + Constants.WSACTUALIZARDATOS;
    axios.get(url)
    .then(res => {
      props.ActualizarUsuario(res.data);
      Auth.setUbicacion({
        departamentoId: res.data.direcciones[0].departamentoId,
        departamento: res.data.direcciones[0].departamento,
        municipioId: res.data.direcciones[0].municipioId,
        municipio: res.data.direcciones[0].municipio,
      })
    })
    .catch(err => {
      Auth.signout();
    });
  }

  useEffect(() => {
    if(Auth.getToken()){
      getUser();
    }

    if(Auth.getCarrito()){
      props.ActualizarCarrito(Auth.getCarrito());
    }
  }, [])

  const NotFoundRedirect = () => <Redirect to='/' />

  return (
    <React.Fragment>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Inicio} />
          <Route exact path="/catalogo/:categoria" component={Catalogo} />
          <Route exact path="/producto/:producto" component={Detalle} />
          <Route exact path="/seccion/:seccion" component={Seccion} />
          <Route exact path="/pack/:pack" component={Pack} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/cobertura" component={Cobertura} />
          <Route exact path="/no-cobertura" component={NoCobertura} />
          <Route exact path="/recuperar-clave" component={Restablecer} />
          <Route exact path="/manual" component={LandingPage} />
          <Route exact path="/preguntas-frecuentes" component={PreguntasFrecuentes} />
          <Route exact path="/politicas-datos" component={()=><PoliticaDatos type={2}/> }/>
          <Route exact path="/terminos-y-condiciones" component={()=><PoliticaDatos type={1}/> } />
          <Route exact path="/garantias-y-devoluciones" component={()=><PoliticaDatos type={4}/> } />
          <Route exact path="/disposiciones-privacidad-seguridad" component={()=><PoliticaDatos type={5}/> } />
          <Route exact path="/carrito" component={Carrito} />
          <PrivateRoute exact path="/pedidos" component={Pedidos} />
          <PrivateRoute exact path="/datos" component={Datos} />
          <Route component={NotFoundRedirect} />
        </Switch>
      </BrowserRouter>
    </React.Fragment>
  )
}

// RUTAS PRIVADAS
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    Auth.getToken()
      ? <Component {...props} />
      : <Redirect to='/login' />
  )} />
)


export default connect(null, {ActualizarUsuario, ActualizarCarrito}) (App);

