import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import firebase from "../../constants/firebase";
import Navbar from "../navbar/Navbar";
import Sub_nav from "../navbar/Sub_nav";
import Footer from "../footer/Footer";
import CardProducto from "../util/CardProducto";
import AlertModal from "../modal/AlertModal";
import axios from "axios";
import Auth from "../../helpers/Auth";
import Search from "../util/Search";
import * as Constants from "../../constants/global";

//REDUX
import ActualizarCarrito from "../../store/carrito/action";
import { selectActiveCarrito } from "../../store/carrito/reducer";
import { selectActiveUser } from "../../store/usuario/reducer";

import noimage from "../../assets/img/logo_login.png";
import minus from "../../assets/icon/minus.svg";
import plus from "../../assets/icon/plus.svg";

const Detalle = (props) => {
  const [search, setSearch] = useState("");
  const [alerta, setAlerta] = useState();
  const [producto, setProducto] = useState();
  const [recomenado, setRecomendado] = useState([]);

  // const loadValidacion = () => {
  //     var url = '';
  //     var cliente = props.usuario;
  //     if(cliente.fechaMayorEdad){
  //         url += "&mayor=true";
  //     }else{
  //         url += "&mayor=false";
  //     }

  //     if(cliente.fechaMayor14){
  //         url += "&catorce=true";
  //     }else{
  //         url += "&catorce=false";
  //     }

  //     return url;
  // }

  const handleSearch = (value) => {
    props.history.push("/catalogo/packs-15?q=" + value);
  };

  const validate = (id, callBack) => {
    if (id == 5 || id == 6) {
      var cliente = props.usuario;
      if (cliente) {
        if (!cliente.fechaMayorEdad && id == 5) {
          setAlerta({
            tipo: "warning",
            titulo: "¿Usted es mayor de edad?",
            mensaje:
              "Recuerde que este tipo de productos solo puede ser vendidos a mayores de edad.",
            btnOk: "Si",
            btnCancel: "No",
            fnOk: function () {
              var cliente = props.usuario;
              if (cliente) {
                let fa = new Date();
                let fe = new Date(
                  fa.getFullYear(),
                  fa.getMonth(),
                  fa.toLocaleString("es-CO", { day: "numeric" }),
                  fa.getHours(),
                  fa.getMinutes(),
                  fa.getMilliseconds()
                );
                axios
                  .put(
                    Constants.URL_BASE +
                      Constants.WSACTUALIZARMAYOR +
                      cliente.id,
                    {
                      fechaMayorEdad: fe.toISOString(),
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${Auth.getToken()}`,
                      },
                    }
                  )
                  .then((response) => {
                    cliente.fechaMayorEdad = fe.toISOString();
                    props.ActualizarUsuario(cliente);
                    document
                      .querySelector(".modal-sucursal")
                      .classList.remove("show-modal");
                    callBack(true);
                  })
                  .catch((err) => {});
              }
            },
            fnCancel: function () {
              callBack(false);
            },
          });
        } else if (!cliente.fechaMayor14 && id == 6) {
          setAlerta({
            tipo: "warning",
            titulo: "¿Usted es mayor de 14 años?",
            mensaje:
              "Recuerde que este tipo de productos solo puede ser vendidos a mayores de 14 años.",
            btnOk: "Si",
            btnCancel: "No",
            fnOk: function () {
              var cliente = props.usuario;
              if (cliente) {
                let fa = new Date();
                let fe = new Date(
                  fa.getFullYear(),
                  fa.getMonth(),
                  fa.toLocaleString("es-CO", { day: "numeric" }),
                  fa.getHours(),
                  fa.getMinutes(),
                  fa.getMilliseconds()
                );
                axios
                  .put(
                    Constants.URL_BASE +
                      Constants.WSACTUALIZARCATORCE +
                      cliente.id,
                    {
                      fechaMayorEdad: fe.toISOString(),
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${Auth.getToken()}`,
                      },
                    }
                  )
                  .then((response) => {
                    cliente.fechaMayor14 = fe.toISOString();
                    props.ActualizarUsuario(cliente);
                    document
                      .querySelector(".modal-sucursal")
                      .classList.remove("show-modal");
                    callBack(true);
                  })
                  .catch((err) => {
                    callBack(false);
                  });
              }
            },
            fnCancel: function () {
              // props.history.push('/');
              callBack(false);
            },
          });
        } else {
          callBack(true);
        }
      } else {
        if (!Auth.getToken()) {
          setAlerta({
            tipo: "info",
            titulo: "Inicio de sesión.",
            mensaje:
              "Por favor inicie sesión o registrese para ver esta categoria",
            btnOk: "Ingresar o Registrarse",
            btnCancel: "Cancelar",
            fnOk: function () {
              props.history.push("/login");
            },
            fnCancel: function () {
              // props.history.push('/');
              callBack(false);
            },
          });
        }
      }
    } else {
      callBack(true);
    }
  };

  // AUMENTA LA CANTTIDAD
  const handleMas = (item) => {
    var ubicacion = Auth.getUbicacion();
    if (Object.keys(ubicacion).length > 0) {
      if(Auth.getToken()){
        firebase
          .analytics()
          .logEvent("agregar_carrito", item.codigoSku + "-" + item.nombre);
        var carrito = props.carrito || {};
        if (carrito[item.codigoSku]) {
          if (carrito[item.codigoSku]["cantidad"] < 99)
            carrito[item.codigoSku]["cantidad"] += 1;
          else {
            props.setAlerta({
              tipo: "error",
              titulo: "Error.",
              mensaje: "¿Lo sentimos, la cantidad máxima por producto es 99",
              btnOk: "Aceptar",
              fnOk: function () {},
              fnCancel: null,
            });
          }
        } else {
          carrito[item.codigoSku] = {
            id: item.id,
            nombre: item.nombre,
            precio: parseFloat(item.precio),
            iva: parseInt(item.iva),
            ico: parseInt(item.ico),
            cantidad: 1,
          };
        }
        Auth.setCarrito(carrito);
        props.ActualizarCarrito(Auth.getCarrito());
      }else{
        props.setAlerta({
          tipo:"info",
          titulo:"Inicia sesión",
          mensaje:"Si deseas agregar este producto a tu pedido, por favor inicia sesión.",
          btnOk:"Iniciar sesión",
          btnCancel:"Cancelar",
          fnOk:function(){
            props.history.push("/login")
          },
          fnCancel:function(){}
        })
      }
    } else {
      props.ActualizarCarrito({});
    }
  };

  const clickCategoria = (categoriaId, categoria) => {
    var id = categoriaId;
    var name = categoria;
    var res =
      name
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .replace(/ /g, "-") +
      "-" +
      id;
    props.history.push("/catalogo/" + res);
  };

  const handleMenos = (item) => {
    var carrito = props.carrito || {};
    if (carrito[item.codigoSku]) {
      if (carrito[item.codigoSku]["cantidad"] == 1) {
        delete carrito[item.codigoSku];
      } else {
        carrito[item.codigoSku]["cantidad"] -= 1;
      }
    }
    Auth.setCarrito(carrito);
    props.ActualizarCarrito(Auth.getCarrito());
  };

  const handleChangeCantidad = (item, valor) => {
    var carrito = props.carrito || {};
    if (valor <= 0) {
      delete carrito[item.codigoSku];
    } else {
      if (carrito[item.codigoSku]) {
        if (valor < 100) carrito[item.codigoSku]["cantidad"] = parseInt(valor);
        else {
          props.setAlerta({
            tipo: "error",
            titulo: "Error.",
            mensaje: "¿Lo sentimos, la cantidad máxima por producto es 99",
            btnOk: "Aceptar",
            fnOk: function () {},
            fnCancel: null,
          });
        }
      } else {
        carrito[item.codigoSku] = {
          id: item.id,
          nombre: item.nombre,
          precio: item.precio,
          cantidad: parseInt(valor),
          iva: parseInt(item.iva),
          ico: parseInt(item.ico),
        };
      }
    }
    Auth.setCarrito(carrito);
    props.ActualizarCarrito(Auth.getCarrito());
  };

  const loadRecomendado = (id) => {
    let url =
      Constants.URL_BASE +
      Constants.WSPRODUCTOS +
      "/Recomendado?ProductoId=" +
      id;

    var ubicacion = Auth.getUbicacion();

    if (Object.keys(ubicacion).length > 0) {
      url +=
        "&DepartamentoId=" +
        ubicacion.departamentoId +
        "&MunicipioId=" +
        ubicacion.municipioId;
    }

    axios.get(url).then((res) => {
      setRecomendado(res.data);
    });
  };

  const loadProducto = (id) => {
    let url =
      Constants.URL_BASE + Constants.WSPRODUCTOS + "/Detalle?ProductoId=" + id;

    var ubicacion = Auth.getUbicacion();

    if (Object.keys(ubicacion).length > 0) {
      url +=
        "&DepartamentoId=" +
        ubicacion.departamentoId +
        "&MunicipioId=" +
        ubicacion.municipioId;
    }

    axios.get(url).then((res) => {
      setProducto(res.data);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (props.match.params.producto) {
      var urlProduct = props.match.params.producto;
      var productArray = urlProduct.split("-");
      var productId = productArray[productArray.length - 1];
      loadProducto(productId);
      loadRecomendado(productId);
    }
  }, [props.match.params.producto]);

  return (
    <React.Fragment>
      <div className="content-carrito">
        <div className="navbar principal navcarrito">
          <Navbar item={2} />
          {/* <Search search={search} updateSearch={handleSearch} /> */}
          <Sub_nav />
        </div>
        <div className="layout">
          {producto ? (
            <div className="detalle">
              <div className="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Inicio</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a
                      onClick={() =>
                        clickCategoria(producto.categoriaId, producto.categoria)
                      }
                    >
                      {producto.categoria}
                    </a>
                  </li>
                  <li className="breadcrumb-item active">{producto.nombre}</li>
                </ol>
              </div>
              <div className="detail-product">
                <div className="detail-content">
                  <div className="detail-gallery"></div>
                  <div className="detail-photo">
                    <img
                      style={producto.agotado == 1 ? { opacity: 0.3 } : {}}
                      className="img-product"
                      src={
                        Constants.URL_BASE +
                        "/image/" +
                        producto.codigoSku.trim() +
                        ".png"
                      }
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = noimage;
                      }}
                    ></img>
                  </div>
                  <div className="detail-info">
                    <p className="detail-shop">Postobón</p>
                    <h2 className="detail-title">
                      <b>{producto.nombre}</b>
                    </h2>
                    <p className="detail-description">{producto.categoria}</p>
                    <p className="detail-description">
                      <b>Descripción:</b>
                    </p>
                    <p className="detail-description">{producto.descripcion}</p>
                    <div className="detail-additional">
                      <p>Quedate en casa, nosotros te llevamos el domicilio</p>
                      <p className="envio">Envio: Gratis</p>
                    </div>
                    <p className="detail-price">
                      {"$ " +
                        new Intl.NumberFormat("en", {
                          numberingSystem: "latn",
                          style: "decimal",
                          currency: "COP",
                        }).format(producto.precio || 0)}
                    </p>
                    {producto.agotado == 0 ? (
                      <div className="action">
                        {props.carrito[producto.codigoSku] ? (
                          <div className="controls">
                            <button
                              role="button"
                              onClick={() => handleMenos(producto)}
                            >
                              <img
                                src={minus}
                                alt="icon minus"
                                width="15px"
                                height="15px"
                              ></img>
                            </button>
                            <input
                              type="number"
                              minLength="0"
                              maxLength="99"
                              onChange={(e) =>
                                handleChangeCantidad(producto, e.target.value)
                              }
                              value={props.carrito[producto.codigoSku].cantidad}
                            />
                            <button
                              role="button"
                              onClick={() => handleMas(producto)}
                            >
                              <img
                                src={plus}
                                alt="icon plus"
                                width="15px"
                                height="15px"
                              ></img>
                            </button>
                          </div>
                        ) : (
                          <button
                            className="agregar"
                            onClick={() => handleMas(producto)}
                          >
                            Agregar
                          </button>
                        )}
                      </div>
                    ) : (
                      <div className="agotado">
                        <p>Agotado</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {recomenado && (
                <React.Fragment>
                  <div className="inicio-titulo">
                    <h2>Recomendados</h2>
                  </div>
                  <div className="pedido-list">
                    <div className="product-card">
                      {recomenado.map((item, i) => (
                        <CardProducto
                          key={i}
                          producto={item}
                          setAlerta={setAlerta}
                        />
                      ))}
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          ) : null}
        </div>
      </div>
      {alerta ? <AlertModal alerta={alerta} updateAlerta={setAlerta} /> : null}
      <Footer />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    usuario: selectActiveUser(state),
    carrito: selectActiveCarrito(state),
  };
};

export default withRouter(
  connect(mapStateToProps, { ActualizarCarrito })(Detalle)
);
