import React, { useEffect } from 'react'
import * as Constants from '../../constants/global'

import close from '../../assets/icon/close_grey.svg';

import { Cookies } from 'react-cookie'
const cookies = new Cookies();

const PublicidadModal = (props) => {

    const handleClose = () => {
        cookies.set("publicidad", "close", {
            maxAge: 60 * 60 * 5
        });
        document.querySelector(".publicidad").classList.remove("show-modal");
    }

    const onClickItem = (item) => {
        window.location.href = item.enlace;
    }

    useEffect(() => {
        document.querySelector(".publicidad").classList.toggle("show-modal");
    }, []);

    return (
        <div className="modal publicidad">
            <div className="modal-content "> 
                <span className="close-button" onClick={() => handleClose()}>
                    <img 
                        src={close} 
                        width="15" 
                        height="15" 
                        alt="icon close"/>
                </span>       
                <div className="content" 
                    onClick={() => onClickItem(props.popup)}
                    style={
                        {
                            backgroundImage: "url(" +Constants.URL_BASE +'popup/'+ props.popup.imagen+ ")",
                            backgroundSize: "100% 100%",
                            borderRadius: "10px"
                        }
                    }>
                </div>
            </div>
        </div>
    )
}

export default PublicidadModal
