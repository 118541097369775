import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import * as Constants from '../../constants/global';
import close from '../../assets/icon/close_grey.svg';
import noimage from '../../assets/img/noimage.png';
import axios from 'axios';

const PoliticaModal = (props) => {

    const [politica, setPolitica] = useState({});


    const handleClose = () => {
        props.handlePolitica();
        document.querySelector(".modal-sucursal").classList.remove("show-modal");
    }

    const getDetalle = () => {
        let url = Constants.URL_BASE + Constants.WSPOLITICA + props.politica
        axios.get(url).then((response)=>{
            setPolitica(response.data)
        }).catch((err)=>{
        })
    }

    useEffect(() => {
        document.querySelector(".modal-sucursal").classList.toggle("show-modal"); 
        getDetalle()        
    }, [])


    return (
        <div className="modal modal-sucursal">
            <div className="modal-content table-content">  
                <div className="title">
                    <p>{politica.nombre}</p>
                    <span className="close-button" onClick={() => handleClose()}>
                        <img 
                            src={close} 
                            width="15" 
                            height="15" 
                            alt="icon close"/>
                    </span>  
                </div>     
                <div className="content">
                    <div dangerouslySetInnerHTML={{__html: politica.texto}}></div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(PoliticaModal);