export const URL_BASE = process.env.REACT_APP_URL_API;
export const WSAUTH = "Auth";
export const WSREGISTRO = "Registrar";
export const WSDEPARTAMENTOS = "Departamento";
export const WSMUNICIPIO = "Municipio/";
export const WSBARRIO = "Barrio/";
export const WSNOCOBERTURA = 'NoCobertura';
export const WSRECUPERAR = 'Recuperar';
export const WSRECUPERARCODIGO = 'Recuperar/enviarcodigo';
export const WSRECUPERARDATOS = 'Recuperar/enviardatos';
export const WSPRODUCTOS = 'Producto';
export const WSCATALOGO = 'Producto/validarCatalogo';
export const WSCATEGORIAS = 'Categoria';
export const WSVERIFICACION = 'Verificar';
export const WSPEDIDO = 'Pedido';
export const WSPOLITICA = 'Politica/';
export const WSREENVIAR = 'verificar/reenviar';
export const WSACTUALIZARDATOS = 'Cliente/';
export const WSPEDIDOSCLIENTE = 'Cliente/pedido';
export const WSDETALLEPEDIDO = 'Cliente/pedido/detalle/';
export const WSCANCELARPEDIDO = 'Cliente/pedido/cancelar';
export const WSVALIDAREDICION = 'Cliente/validar/direccion/';
export const WSACTUALIZARDIRECCION = 'Cliente/direccion/';
export const WSACTUALIZARMAYOR = 'Cliente/mayoredad/';
export const WSACTUALIZARCATORCE = 'Cliente/mayorcatorce/';
export const WSFECHAENTREGA = 'FechaEntrega';
export const WSTOPEPEDIDO = 'TopePedido';
export const WSSECCION = 'Seccion';
export const WSBANNER = 'Publicidad/Banner';
export const WSPOPUP = 'Publicidad/PopUp';
export const WSPRODUCTOHOME = 'Producto/Home';
export const WSSECCIONPRODUCTO = 'Seccion/Producto';



export const style = {
    'padding':'6px',
    'width':'100%', 
    'height':'0px',
    'text-align':'center',
    'border-top':'0px',
    'border-bottom': '#757575 solid 1px',
}